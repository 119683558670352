import React, {useContext, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UserContext from "../../../../context/UserProvider";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

function PriceMatrix(props) {
    const {t} = useTranslation();
    const isPriceMatrixEnabled = false;

    // CURRENCY
    const {activeCurrency} = useContext(UserContext);
    const {priceMatrix, repeatOrderMode} = props.matrix;

    const [previousBusinessDay, setPreviousBusinessDay] = useState(0);
    const [businessDaySelected, setBusinessDaySelected] = useState(0);
    const [nextBusinessDay, setNextBusinessDay] = useState(0);

    function createRowPriceMatrix(
        quantity,
        pricePreviousDay,
        priceDDay,
        priceNextDay,
    ) {
        return { quantity, pricePreviousDay, priceDDay, priceNextDay };
    }

    const [rowsPM, setRowsPM] = useState([])

    useEffect(() => {

        if (priceMatrix === null || priceMatrix === undefined){
            return
        }

        if (Object.keys(priceMatrix).length === 0 || repeatOrderMode.lockPrice) {

            setRowsPM([
                createRowPriceMatrix("-", "-", "-", "-"),
                createRowPriceMatrix("-", "-", "-", "-"),
                createRowPriceMatrix("-", "-", "-", "-"),
                createRowPriceMatrix("-", "-", "-", "-"),
                createRowPriceMatrix("-", "-", "-", "-"),
            ])

            return
        }

        let quantityQuarter = priceMatrix.quarter[0]?.quantity > 0 ? priceMatrix.quarter[0].quantity : "-";
        let quantityHalf = priceMatrix.half[0]?.quantity > 0 ? priceMatrix.half[0].quantity : "-";
        let quantitySelected = priceMatrix.selected[0]?.quantity > 0 ? priceMatrix.selected[0].quantity : "-";
        let quantitySesqui = priceMatrix.sesqui[0]?.quantity > 0 ? priceMatrix.sesqui[0].quantity : "-";
        let quantityDouble = priceMatrix.double[0]?.quantity > 0 ? priceMatrix.double[0].quantity : "-";

        let unitPricePreviousDayQuarter = priceMatrix.quarter[0]?.unitPrice > 0 ? priceMatrix.quarter[0].unitPrice.toFixed(2) : "-";
        let unitPriceSelectedDayQuarter = priceMatrix.quarter[1]?.unitPrice > 0 ? priceMatrix.quarter[1].unitPrice.toFixed(2) : "-";
        let unitPriceNextDayQuarter = priceMatrix.quarter[2]?.unitPrice > 0 ? priceMatrix.quarter[2].unitPrice.toFixed(2) : "-";

        let unitPricePreviousDayHalf = priceMatrix.half[0]?.unitPrice > 0 ? priceMatrix.half[0].unitPrice.toFixed(2) : "-";
        let unitPriceSelectedDayHalf = priceMatrix.half[1]?.unitPrice > 0 ? priceMatrix.half[1].unitPrice.toFixed(2) : "-";
        let unitPriceNextDayHalf = priceMatrix.half[2]?.unitPrice > 0 ? priceMatrix.half[2].unitPrice.toFixed(2) : "-";

        let unitPricePreviousDay = priceMatrix.selected[0]?.unitPrice > 0 ? priceMatrix.selected[0].unitPrice.toFixed(2) : "-";
        let unitPriceSelectedDay = priceMatrix.selected[1]?.unitPrice > 0 ? priceMatrix.selected[1].unitPrice.toFixed(2) : "-";
        let unitPriceNextDay = priceMatrix.selected[2]?.unitPrice > 0 ? priceMatrix.selected[2].unitPrice.toFixed(2) : "-";

        let unitPricePreviousDaySesqui = priceMatrix.sesqui[0]?.unitPrice > 0 ? priceMatrix.sesqui[0].unitPrice.toFixed(2) : "-";
        let unitPriceSelectedDaySesqui = priceMatrix.sesqui[1]?.unitPrice > 0 ? priceMatrix.sesqui[1].unitPrice.toFixed(2) : "-";
        let unitPriceNextDaySesqui = priceMatrix.sesqui[2]?.unitPrice > 0 ? priceMatrix.sesqui[2].unitPrice.toFixed(2) : "-";

        let unitPricePreviousDayDouble = priceMatrix.double[0]?.unitPrice > 0 ? priceMatrix.double[0].unitPrice.toFixed(2) : "-";
        let unitPriceSelectedDayDouble = priceMatrix.double[1]?.unitPrice > 0 ? priceMatrix.double[1].unitPrice.toFixed(2) : "-";
        let unitPriceNextDayDouble = priceMatrix.double[2]?.unitPrice > 0 ? priceMatrix.double[2].unitPrice.toFixed(2) : "-";

        setPreviousBusinessDay(priceMatrix.selected[0]?.businessDays > 0 ? priceMatrix.selected[0].businessDays : priceMatrix.selected[1].businessDays - 1);
        setBusinessDaySelected(priceMatrix.selected[1]?.businessDays > 0 ? priceMatrix.selected[1].businessDays : "-");
        setNextBusinessDay(priceMatrix.selected[2]?.businessDays > 0 ? priceMatrix.selected[2].businessDays : "-");

        setRowsPM([
            createRowPriceMatrix(
                `${quantityQuarter}`,
                `${unitPricePreviousDayQuarter}`,
                `${unitPriceSelectedDayQuarter}`,
                `${unitPriceNextDayQuarter}`),
            createRowPriceMatrix(
                `${quantityHalf}`,
                `${unitPricePreviousDayHalf}`,
                `${unitPriceSelectedDayHalf}`,
                `${unitPriceNextDayHalf}`),
            createRowPriceMatrix(
                `${quantitySelected}`,
                `${unitPricePreviousDay}`,
                `${unitPriceSelectedDay}`,
                `${unitPriceNextDay}`),
            createRowPriceMatrix(
                `${quantitySesqui}`,
                `${unitPricePreviousDaySesqui}`,
                `${unitPriceSelectedDaySesqui}`,
                `${unitPriceNextDaySesqui}`),
            createRowPriceMatrix(
                `${quantityDouble}`,
                `${unitPricePreviousDayDouble}`,
                `${unitPriceSelectedDayDouble}`,
                `${unitPriceNextDayDouble}`),
        ])

    }, [priceMatrix]);

    return (
        <>
            {/*NEW PRICE MATRIX*/}
            {
                isPriceMatrixEnabled &&
                <>
                    <TableContainer className="project-panel-price-matrix" component={Paper}>
                        <Table size="medium" aria-label="Price matrix">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t("common.delivery")}</TableCell>
                                    <TableCell align="right">{previousBusinessDay} BD<sup>*</sup></TableCell>
                                    <TableCell align="right">{businessDaySelected} BD<sup>*</sup></TableCell>
                                    <TableCell align="right">{nextBusinessDay} BD<sup>*</sup></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsPM.length > 0 && rowsPM.map((row, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                              className={index === 2  ? "tableCellQuantityCustomer" : ""}>
                                        <TableCell align="center">{row.quantity} {row.quantity > 0 && t("common.pcb")}</TableCell>
                                        <TableCell align="right">{row.pricePreviousDay} {row.pricePreviousDay > 0 && activeCurrency}</TableCell>
                                        <TableCell align="right">{row.priceDDay} {row.priceDDay > 0 && activeCurrency}</TableCell>
                                        <TableCell align="right">{row.priceNextDay} {row.priceNextDay > 0 && activeCurrency}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box component={"span"} className="price-matrix-legend">* {t("common.businessDay")}</Box>
                </>
            }
            <span>{t("calculators.projectPanel.priceMatrixUnavailble")}</span>
        </>
    );
}
export default PriceMatrix;