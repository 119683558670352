import {createContext, useState} from "react";
import * as React from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
const CalculatorLabServicesContext = createContext({});

export const CalculatorLabServicesProvider = ({ children }) => {

    //Cookies
    const getStorageValue = (key) => {
        if (typeof window !== "undefined") {
            const itemInLS = localStorage.getItem(key);
            return itemInLS !== null ? JSON.parse(itemInLS) : null;
        }
    }
    //Useful for POST Api
    const axiosPrivate = useAxiosPrivate();

    const {auth} = useAuth();
    const [customer_id, setCustomer_id] = useState(getStorageValue('rigid-flex')?.rigidFlex.customer_id || parseInt(auth.customerId));

    //BackOffice connexion
    const [connectToBO, setConnectToBO] = useState(false);
    const [errorConnectionToBO, setErrorConnectionToBO] = useState(false);

    const [value, setValue] = React.useState(0);
    const [file, setFile] = useState([]);
    const [unityFormat, setUnityFormat] = useState('mm');

    //ALLSTEPS
    const [length, setLength] = useState(getStorageValue('laboratory-services')?.labServices.length || 120);
    const [width, setWidth] = useState(getStorageValue('laboratory-services')?.labServices.width || 120);
    const [returnPCB, setReturnPCB] = useState(getStorageValue('laboratory-services')?.labServices.returnPCB || true);
    const [description, setDescription] = useState(getStorageValue('laboratory-services')?.labServices.description || "");

    // Useful for Project Panel
    const [testing_time, setTesting_time] = useState(0);
    const [test_price, setTest_price] = useState(0);
    const [pick_up_cost, setPick_up_cost] = useState(0);
    const [return_cost, setReturn_cost] = useState(0);
    const [total_price, setTotal_price] = useState(0);
    const [isTestLab, setIsTestLab] = useState(true);
    const [isNA_lab, setIsNA_lab] = useState(false);
    const [selected, setSelected] = React.useState([]);

    const [partNumber, setPartNumber] = useState('');
    const [persist, setPersist] = useState(true);
    let _persist = true;

    const [productVariant, setProductVariant] = useState({});
    const [isRepeatOrder, setIsRepeatOrder] = useState(false);
    const [customerReferenceChoose, setCustomerReferenceChoose] = useState({title: '', isNewReference: false})

    const [customerReferences, setCustomerReferences] = useState([]);
    const [shippingCost_PP, setShippingCost] = useState(0);
    const [voucher, setVoucher] = useState([]);
    const [weight, setWeight] = useState(0);


    //Reset from table to table
    function handleResetProjectPanel(){
        setSelected([]);
        setTest_price(0);
        setTesting_time(0);
        setPick_up_cost(0);
        setReturn_cost(0);
        setTotal_price(0);
    }

    //tst_price -> Local variable for test_price / pk_up_cost -> Local variable for pick_up_cost / rtn_cost -> Local variable for return_cost
    // && total -> total_price
    function calcTotalPrice (tst_price, pk_up_cost, rtn_cost) {

        let total = Math.round((tst_price + pk_up_cost + rtn_cost) * 100) / 100;
        setTotal_price(total);

    }

    return (
        <CalculatorLabServicesContext.Provider value={{
            customer_id, setCustomer_id,
            length, setLength,
            width, setWidth,
            returnPCB, setReturnPCB,
            description, setDescription,
            testing_time, setTesting_time,
            test_price, setTest_price,
            pick_up_cost, setPick_up_cost,
            return_cost, setReturn_cost,
            total_price, setTotal_price,
            isTestLab, setIsTestLab,
            isNA_lab, setIsNA_lab,
            selected, setSelected,
            handleResetProjectPanel,
            calcTotalPrice,
            partNumber, setPartNumber,
            persist, setPersist,
            isRepeatOrder, setIsRepeatOrder,
            customerReferenceChoose, setCustomerReferenceChoose,
            productVariant, setProductVariant,
            customerReferences, setCustomerReferences,
            getStorageValue,
            axiosPrivate,
            connectToBO, setConnectToBO,
            errorConnectionToBO, setErrorConnectionToBO,
            file, setFile,
            value, setValue,
            auth,
            unityFormat, setUnityFormat,
            shippingCost_PP, setShippingCost,
            voucher, setVoucher,
            weight, setWeight,
        }} displayName="CalculatorLabServices">
            {children}
        </CalculatorLabServicesContext.Provider>
    )
}

export default CalculatorLabServicesContext;
