import React, {useContext, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {Logout} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/auth.service";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Typography from "@mui/material/Typography";
import UserContext from "../../context/UserProvider";
import {useTranslation} from "react-i18next";
import useAuth from "../../hooks/useAuth";
import {logInConsole} from "../../tools/debug";

export const TopBar = (props) => {
    const {auth} = useAuth();
    const navigate = useNavigate();
    const {firstName, lastName} = useContext(UserContext);
    const menuTopBar = props.menuTopBar;

    // USER MENU
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const handleUserMenu = (route) => {
        route();
        setIsUserMenuOpen(false);
    }
    const logout = () => {
        AuthService.logout();
        navigate('/', { replace: true});
        // window.location.reload();
    }
    const login = () => {
        navigate('/login', { replace: false});
    }
    const myAccount = () => {
        navigate('/user', { replace: false});
    }
    const changelog = () => {
        navigate('/changelog', { replace: false});
    }

    // CURRENCY
    const [isCurrencySwitcherOpen, setIsCurrencySwitcherOpen] = useState(false);
    const {activeCurrency, setActiveCurrency} = useContext(UserContext);
    const {currencyCode, setCurrencyCode} = useContext(UserContext);
    const currenciesAllowed = props.currencies

    const handleCurrencySwitcher = (newCurrency) => {
        setCurrencyCode(newCurrency.code);
        setActiveCurrency(newCurrency.symbol);
        setIsCurrencySwitcherOpen(false);
        logInConsole(`currencyCode : ${newCurrency.code}`, 'blue');
    }


    // LANGUAGES
    const [isLanguageSwitcherOpen, setIsLanguageSwitcherOpen] = useState(false);
    const {activeLanguage, setActiveLanguage} = useContext(UserContext);
    const languages = props.languages
    const {t, i18n} = useTranslation();

    const handleLanguageSwitcher = (newLanguage, newLocale) => {
        setActiveLanguage(newLanguage);
        i18n.changeLanguage(newLocale);
        setIsLanguageSwitcherOpen(false);
    }

    return(
        <>
            <Box className="top-bar-wrapper">
                <Box className="top-bar-container">
                    <Box className={"top-bar-items"}>
                        <a href={menuTopBar[0]?.url} target={menuTopBar[0]?.newTab ? "_blank" : "_self"}>
                            {t(menuTopBar[0]?.name)}
                        </a>
                        <a href={menuTopBar[1]?.url} target={menuTopBar[1]?.newTab ? "_blank" : "_self"}>
                            {t(menuTopBar[1]?.name)}
                        </a>
                        <a href={menuTopBar[2]?.url} target={menuTopBar[2]?.newTab ? "_blank" : "_self"}>
                            {t(menuTopBar[2]?.name)}
                        </a>
                    </Box>
                    <Box className={"top-bar-tools"}>

                        {/*CONTACT*/}
                        <a href={menuTopBar[3]?.url} target={menuTopBar[3]?.newTab ? "_blank" : "_self"}>
                            {t(menuTopBar[3]?.name)}
                        </a>


                        {/*USER MENU*/}
                        <Box className="secondary-dropdown-container"
                             onMouseEnter={auth.username === undefined ? null : () => setIsUserMenuOpen(true)}
                             onMouseLeave={() => setIsUserMenuOpen(false)}
                             onClick={auth.username === undefined ? () => handleUserMenu(login) : null}>
                            <Box component={"span"}
                                        className={isUserMenuOpen ? "active-menu tools-with-icon" : "tools-with-icon"}>
                                <svg width="18"
                                     viewBox="0 0 16 16"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0C6.41775 0 4.87104 0.469191 3.55545 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59967 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1572 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8 4.525C8.32535 4.525 8.6434 4.62148 8.91392 4.80223C9.18444 4.98299 9.39528 5.2399 9.51979 5.54049C9.64429 5.84107 9.67687 6.17182 9.6134 6.49092C9.54993 6.81002 9.39326 7.10313 9.1632 7.33319C8.93314 7.56325 8.64003 7.71992 8.32093 7.78339C8.00183 7.84686 7.67108 7.81429 7.3705 7.68978C7.06991 7.56528 6.81299 7.35443 6.63224 7.08391C6.45148 6.81339 6.35501 6.49535 6.35501 6.17C6.35488 5.95394 6.39733 5.73997 6.47995 5.54033C6.56258 5.34069 6.68374 5.15929 6.83652 5.00652C6.9893 4.85374 7.1707 4.73257 7.37034 4.64995C7.56997 4.56733 7.78394 4.52487 8 4.525ZM10.864 11.475H5.14V10.237C5.13974 10.0234 5.18162 9.81184 5.26324 9.61445C5.34486 9.41705 5.46463 9.2377 5.61567 9.08666C5.76671 8.93562 5.94606 8.81586 6.14345 8.73424C6.34085 8.65261 6.5524 8.61074 6.766 8.611H9.24001C9.67074 8.61206 10.0835 8.78377 10.3879 9.08853C10.6923 9.3933 10.8635 9.80627 10.864 10.237V11.475Z" fill="#299FDE"/>
                                </svg>
                                {auth.username === undefined ? t("common.connect") : `${firstName} ${lastName}`}
                            </Box>

                            {isUserMenuOpen === true ?
                                <Box className="menu-items user-menu">
                                    <Box className="user-menu-items">
                                        <svg width="18"
                                             viewBox="0 0 16 16"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 0C6.41775 0 4.87104 0.469191 3.55545 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59967 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1572 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8 4.525C8.32535 4.525 8.6434 4.62148 8.91392 4.80223C9.18444 4.98299 9.39528 5.2399 9.51979 5.54049C9.64429 5.84107 9.67687 6.17182 9.6134 6.49092C9.54993 6.81002 9.39326 7.10313 9.1632 7.33319C8.93314 7.56325 8.64003 7.71992 8.32093 7.78339C8.00183 7.84686 7.67108 7.81429 7.3705 7.68978C7.06991 7.56528 6.81299 7.35443 6.63224 7.08391C6.45148 6.81339 6.35501 6.49535 6.35501 6.17C6.35488 5.95394 6.39733 5.73997 6.47995 5.54033C6.56258 5.34069 6.68374 5.15929 6.83652 5.00652C6.9893 4.85374 7.1707 4.73257 7.37034 4.64995C7.56997 4.56733 7.78394 4.52487 8 4.525ZM10.864 11.475H5.14V10.237C5.13974 10.0234 5.18162 9.81184 5.26324 9.61445C5.34486 9.41705 5.46463 9.2377 5.61567 9.08666C5.76671 8.93562 5.94606 8.81586 6.14345 8.73424C6.34085 8.65261 6.5524 8.61074 6.766 8.611H9.24001C9.67074 8.61206 10.0835 8.78377 10.3879 9.08853C10.6923 9.3933 10.8635 9.80627 10.864 10.237V11.475Z" fill="#299FDE"/>
                                        </svg>
                                        <Typography component={"span"} onClick={() => handleUserMenu(myAccount)}>
                                            {t("common.myAccount")}
                                        </Typography>
                                    </Box>
                                    {/*TODO ONLY FOR ADMIN*/}
                                    {/*<Box className="user-menu-items">*/}
                                    {/*    <MoveUpIcon fontSize="small" />*/}
                                    {/*    <Typography component={"span"} onClick={() => handleUserMenu(changelog)}>*/}
                                    {/*        {t("common.version")} {process.env.REACT_APP_VERSION}*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}
                                    <Box className="user-menu-items">
                                        <Logout fontSize="small" />
                                        <Typography component={"span"} onClick={() => handleUserMenu(logout)}>
                                            {t("common.logout")}
                                        </Typography>
                                    </Box>
                                </Box>
                                : null
                            }
                        </Box>

                        {/*LANGUAGE SWITCHER*/}
                        <Box className="secondary-dropdown-container" onMouseEnter={() => setIsLanguageSwitcherOpen(true)}
                             onMouseLeave={() => setIsLanguageSwitcherOpen(false)}>
                            <Box component={"span"}
                                 className={isLanguageSwitcherOpen ? "active-menu tools-with-icon" : "tools-with-icon"}>
                                {activeLanguage}
                            </Box>

                            <svg width="9"
                                 viewBox="0 0 8 6"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6L0 0L8 0L4 6Z"/>
                            </svg>

                            {isLanguageSwitcherOpen === true ?
                                <Box className="menu-items">
                                    {
                                        languages.map((item) => (
                                            <React.Fragment key={item.name}>
                                                {item.active ?
                                                    <Typography component={"span"}
                                                                onClick={() => handleLanguageSwitcher(item.shortcode, item.locale)}
                                                                className={activeLanguage === item.shortcode ? "disabled" : ""}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                    : null
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </Box>
                                : null
                            }
                        </Box>

                        {/*CURRENCY SWITCHER*/}
                        {
                            auth.username !== undefined &&
                            <>
                                <Box className="secondary-dropdown-container" onMouseEnter={() => setIsCurrencySwitcherOpen(true)}
                                     onMouseLeave={() => setIsCurrencySwitcherOpen(false)}>
                                    <Box component={"span"}
                                         className={isCurrencySwitcherOpen ? "active-menu tools-with-icon" : "tools-with-icon"}>
                                        {activeCurrency}
                                    </Box>

                                    <svg width="9"
                                         viewBox="0 0 8 6"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 6L0 0L8 0L4 6Z"/>
                                    </svg>

                                    {isCurrencySwitcherOpen === true ?
                                        <Box className="menu-items">
                                            {
                                                currenciesAllowed.map((item) => (
                                                    <React.Fragment key={item.code}>
                                                        <Typography component={"span"}
                                                                    onClick={() => handleCurrencySwitcher(item)}
                                                                    className={currencyCode === item.code ? "disabled" : ""}
                                                        >
                                                            {item.code}&nbsp;{item.symbol}
                                                        </Typography>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </Box>
                                        : null
                                    }
                                </Box>
                            </>
                        }

                    </Box>
                </Box>
            </Box>
        </>
    )
}
