import React from "react";
import TechnologyCard from "./technology-card/technology-card";
import './list-technologies.scss';

function ListTechnologies(props) {

    return (
        <div className="technologies">
            {props.technologies.map( (technology => {
               return <TechnologyCard
                   key={technology.id}
                   name={technology.name}
                   image={technology.image}
                   description={technology.description}
                   link={technology.link}
                   active={technology.active}
                   repeat={technology.repeat}
               />
            }))}
        </div>
    )
}

export default ListTechnologies;
