import React from "react";
import ListTechnologies from "../technologies/list-technologies";
import VideoHeader from "./video-header/video-header";
import Popup from "../popup/popup";


function Home(props) {

    const popup = JSON.parse(localStorage.getItem("popup")) === null ? {showed: false} : JSON.parse(localStorage.getItem("popup"));
    const isPopupActive = false;

    return (
        <>
            {isPopupActive && !popup.showed && <Popup/>}
            <VideoHeader/>
            <ListTechnologies technologies={props.technologies}/>
        </>
    )
}

export default Home;
